import React from "react";
import config from "../config/index.js";

export default function Footer(props) {
  return (
    <footer className="main-footer">
      <div className="container">
        <div className="footer-card">
          {/* <div>
          <h6>Smart-contract:</h6>
          <p>{config.Contract} </p>
          </div> */}
          {/* <div className="footer-social">
            
            <a href="#" target="_blank">
              <span>
                <img
                  src={require("../assets/images/facebook-icon.png")}
                  className="img-fluid"
                  alt="face"
                />
              </span>
            </a>
            <a href="#" target="_blank">
              <span>
                <img
                  src={require("../assets/images/x-icon.png")}
                  alt="img"
                  className="img-fluid"
                />
              </span>
            </a>
            <a href="#" target="_blank">
              <span>
                <img
                  src={require("../assets/images/linkedin-icon.png")}
                  alt="img"
                  className="img-fluid"
                />
              </span>
            </a>
            <a href="#" target="_blank">
              <span>
                <img
                  src={require("../assets/images/telegram-icon.png")}
                  alt="img"
                  className="img-fluid"
                />
              </span>
            </a>
            <a href="#" target="_blank">
              <span>
                <img
                  src={require("../assets/images/youtube-icon.png")}
                  className="img-fluid"
                  alt="logo"
                />
              </span>
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
}
