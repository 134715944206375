import React, { useState } from "react";
import Form from "react-bootstrap/Form";

function RegistrationNotification() {
  const [showPasswordprotectionTab1, setShowPasswordprotectionTab1] =
    useState(true); // Initialize showPasswordprotectionTab1 to true
  const [showPasswordprotectionTab2, setShowPasswordprotectionTab2] =
    useState(false);
  const [activeTab, setActiveTab] = useState("PasswordprotectionTab1"); // State variable for active tab

  const togglePasswordprotectionTab1 = () => {
    setShowPasswordprotectionTab1(true);
    setShowPasswordprotectionTab2(false);
    setActiveTab("PasswordprotectionTab1"); // Set activeTab to "PasswordprotectionTab1" when tab 1 is clicked
  };

  const togglePasswordprotectionTab2 = () => {
    setShowPasswordprotectionTab1(false);
    setShowPasswordprotectionTab2(true);
    setActiveTab("PasswordprotectionTab2"); // Set activeTab to "PasswordprotectionTab2" when tab 2 is clicked
  };

  return (
    <>
      <div className="container">
        <h2>React Collapse Example with Toggle Buttons</h2>

        <div
          className={`tab_content_1_sub ${
            activeTab === "PasswordprotectionTab1" ? "active" : ""
          }`}
          onClick={togglePasswordprotectionTab1}
        >
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="notificationOptions"
              id="noNotification"
              data-toggle="collapse"
              data-target="#PasswordprotectionImg1"
              aria-expanded="false"
              aria-controls="PasswordprotectionImg1"
              checked={activeTab === "PasswordprotectionTab1"} // Check if activeTab is "PasswordprotectionTab1"
            />
            <label className="form-check-label" htmlFor="noNotification">
              <h6>No password needed</h6>
              <p>
                Anyone with a valid link will be able to access the webinar
                room.
              </p>
            </label>
          </div>
        </div>

        <div
          className={`tab_content_1_sub2 ${
            activeTab === "PasswordprotectionTab2" ? "active" : ""
          }`}
          onClick={togglePasswordprotectionTab2}
        >
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="notificationOptions"
              id="sendNotification"
              data-toggle="collapse"
              data-target="#PasswordprotectionImg2"
              aria-expanded="false"
              aria-controls="PasswordprotectionImg2"
              checked={activeTab === "PasswordprotectionTab2"} // Check if activeTab is "PasswordprotectionTab2"
            />
            <label className="form-check-label" htmlFor="sendNotification">
              <h6> Password required</h6>
              <p>
                Both a valid link and a secret password will be required to
                enter the webinar room.
              </p>
            </label>
          </div>
        </div>

        {showPasswordprotectionTab1 && (
          <div className="collapse show">
            <div>
              <div className="img-bg">
                dvc 
                {/* <img
                  src={require("../../assets/images/Passwordprotection_img.png")}
                  className="img-fluid"
                  alt="Notification Image 1"
                /> */}
              </div>
            </div>
          </div>
        )}

        {showPasswordprotectionTab2 && (
          <div className="collapse show">
            <div>
              <div className="img-bg">
                fdkngkl
                {/* <img
                  src={require("../../assets/images/Passwordprotection_img_1.png")}
                  className="img-fluid"
                  alt="Notification Image 2"
                /> */}
              </div>
            </div>
          </div>
        )}

        {showPasswordprotectionTab2 && (
          <div
            className="collapse multi-collapse select_box_1"
            id="PasswordprotectionImg2"
          >
            <Form.Label>Recipient's email</Form.Label>
            <Form.Select aria-label="Default select example">
              <option>Select item</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </div>
        )}
      </div>
    </>
  );
}

export default RegistrationNotification;