import React ,{ useState }  from "react";
function PackageHis({ poolInfo }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      
      <div className="dashboard_table">
        <div className={`dashboard_header ${isExpanded ? 'expanded' : 'not-expanded'}`}>
        <div className="mobile_img_none">
            <img
                src={require("../../assets/images/dashboard/user_icon_6.png")}
                className="img-fluid"
                />
        <h6>Upgrade Details</h6>
        </div>
        <img
              src={require("../../assets/images/dashboard/arrow.png")}
              className="img-fluid me-5 rotate"
              data-bs-toggle="collapse"
              href="#collapseExamplethree"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
              onClick={toggleCollapse}
            />
        </div>
        <div className=" collapse collapse_content" id="collapseExamplethree">
        {poolInfo && poolInfo?.length != 0 ? (
          <div className="table-responsive" >
            <table class="table mt-4">
              <thead>
                <tr>
                  <th scope="col">Level</th>
                  <th scope="col">Upgrades</th>
                  <th scope="col">Upgraded</th>
                  <th scope="col">Active</th>
                </tr>
              </thead>
              <tbody>
                {poolInfo &&
                  poolInfo.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.poolAmount} USD</td>
                      <td>{(item.investAmount).toFixed(2)} USD</td>
                      <td>{item.investAmount > 0 ? "Active" : "Inactive"}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <center>
            <h6>No History</h6>
          </center>
        )}
        </div>
      </div>
    </>
  );
}

export default PackageHis;
