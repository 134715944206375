import AOS from "aos";
import "aos/dist/aos.css";
import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/esm/Container.js";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ConnectWallet from "../components/ConnectWallet.js";
import Footer from "../components/footer.js";
import Navbar from "../components/navbar.js";
import Web3 from "web3";
import { Multicall } from "ethereum-multicall";
import { getFormatMulticall1, bignumber } from "../helper/Action.js";

//config
import config from "../config/index.js";

//ABI
import contractAbi from "../ABI/MLMContract.json";

const PrevArrow = (props) => {
  const { className, onClick } = props;

  return (
    <div className={className} onClick={onClick}>
      <span className="arrow-img"></span>
    </div>
  );
};

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <span className="arrow-img"></span>
    </div>
  );
};

export default function Home(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  //Info state - index info
  /* 
  index = 0 - 'currUserID'
  index = 1 - 'getPoolLenght'
  index = 2 - 'totalIncome'
  */
  const [info, setInfo] = useState([]);

  useEffect(() => {
    let web3 = new Web3(config?.rpcurl);
    let contractcall = new web3.eth.Contract(contractAbi, config.Contract);
    const web3call = new Web3(window.ethereum);
    const multicall = new Multicall({ web3Instance: web3call });
    const info = getInfo(multicall);
  }, []);

  const getInfo = async (multicall) => {
    const functionName = new Array(
      "currUserID",
      "getPoolLenght",
      "totalIncome"
    );
    let infoMultiCallArr = new Array();
    functionName.forEach(function (fname, index) {
      let McallObj = {
        reference: `info-${index}`,
        contractAddress: config.Contract,
        abi: contractAbi,
        calls: [
          {
            reference: fname,
            methodName: fname,
            methodParameters: [],
          },
        ],
      };
      infoMultiCallArr.push(McallObj);
    });

    let _info = new Array();
    try {
      const multiCallResults = await multicall.call(infoMultiCallArr);
      const resData = await Promise.all(
        infoMultiCallArr.map((_, i) =>
          getFormatMulticall1(multiCallResults, `info-${i}`, 0)
        )
      );
      _info = await Promise.all(
        resData.map(async (element, index) => {
          const _cdata = await bignumber(element[0]);
          return _cdata;
        })
      );
    } catch (error) {
      console.error("Error creating genealogy tree:", error);
      return null;
    }
    setInfo(_info);
  };

  const settings_1 = {
    dots: false,
    infinite: true,
    centerMode: true,
    centerPadding: "0px",
    speed: 500,
    slidesToShow: 3,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <header className="page_header">
        <Navbar />
      </header>
      <section className="home_section">
        <Container>
          <Row>
            <Col lg={6}>
              <div
                className="home-banner-content mt-5"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-duration="500"
              >
                <h5 className="h5_tag">
                Unlock the <span>FUTURE <br></br>OF DECENTRALIZED FINANCE WITH US</span>{" "}
                  
                </h5>

                <p className="mt-5">
                  DeFiQ Plus platforms is a top rewards program protocol for
                  crypto that allows users to stake their DeFiQ crypto tokens to
                  earn staking rewards.
                </p>
                <div className="home_btn mt-5">
                  {!walletConnection?.address ? (
                    <ConnectWallet />
                  ) : walletConnection?.userdetails?.isExist ? (
                    <button className="primary_btn">
                      <a className="dropdown-item" href="/dashboard">
                        Dashboard
                      </a>
                    </button>
                  ) : (
                    <button className="primary_btn">
                      {" "}
                      <a className="dropdown-item" href="/join-now">
                        Join now
                      </a>
                    </button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="home_section_features">
        <Container>
          <div classname="section_features">
            <Row>
              <Col lg={12}>
                <div
                  className="home_features_content mt-3"
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-duration="1000"
                >
                  <h5 className="h5_tag text-center">
                    Our <span>Technologies </span>{" "}
                  </h5>

                  <p className="text-center mt-3">
                    "Poised to revolutionize global economics through fully
                    decentralized automated systems on web3 technology."
                  </p>
                </div>
              </Col>
            </Row>
            <ul
              className="features_list"
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-duration="1000"
            >
              <li>
                <div className="home_features mt-5">
                  <h6>Stable Coins </h6>

                  <p className="mt-3">
                    By registering $100 in DeFiQ Plus you will get $20 worth
                    DeFiQ Tokens in your user panel, and it will be staked upto
                    18 months from the date of registered in DeFiQ Plus.
                  </p>
                </div>
              </li>
              <li>
                <div className="home_features mt-5">
                  <h6>Smart Contract</h6>

                  <p className="mt-3">
                    Once you registered with 100 USDT package you are eligible
                    to get direct referral rewards by using your referral link.
                    When any new members register by using your referral code or
                    your upliner’s code they placed as per the vacant place
                    under your 1st, Level or 2nd level A or B you will receive
                    MATRIX AUTO UPGRADE income.
                  </p>
                </div>
              </li>
              <li>
                <div className="home_features mt-5">
                  <h6>Blockchain</h6>

                  <p className="mt-3">
                    100 % Decentralized and get instant rewards from blockchain.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </Container>
      </section>
      <section className="home_web3_projects">
        <Container>
          <Row>
            <Col lg={12}>
              <div
                className="home_web3_content"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-duration="1000"
              >
                <h5 className="h5_tag text-center">
                  <span>Earn money </span> by promoting Web3 projects!
                </h5>

                <p className="text-center mt-3">
                  We collaborate with top verified Web3 projects. Share them
                  with the world and get paid $$$.
                </p>
              </div>
            </Col>
            <Col lg={12}>
              <div
                className="text-center web3_text"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-duration="1000"
              >
                <img
                  src={require("../assets/images/banner_img_1.png")}
                  className="img-fluid"
                />
                <p
                  className="text-center mt-3"
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-duration="1000"
                >
                  DeFiQ is a blockchain platform that provides users with
                  blockchain infrastructure technologies and scalable white
                  label dApp solutions for decentralized finance, GameFi, and
                  NFT.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="home-blog-slider">
        <div className="container">
          <div className="home-content-secondary pt-4" data-aos="fade-up"
            data-aos-offset="300" data-aos-duration="1000">
            <h5 className="h5_tag text-center">
              <span> Recent </span>platform activity{" "}
            </h5>

            <p className="text-center mt-3">
              "Poised to revolutionize global economics through fully
              decentralized automated systems on web3 technology."
            </p>
          </div>
          <div className="slider_1" data-aos="fade-up"
            data-aos-offset="300"
            data-aos-duration="1000">
            <Slider {...settings_1}>
              <div>
                <div className="home-blog-card" >
                  <img
                    src={require("../assets/images/profile_img.png")}
                    alt="img"
                    className="img-fluid mx-auto mb-4 slider_img"
                  />
                  <span>New user joined</span>
                  <h3 className="mt-4">
                    <a href="#">ID 124587</a>
                  </h3>
                  <p className="d-flex justify-content-center mt-4">
                    <img
                      src={require("../assets/images/download_arrow.png")}
                      alt="img"
                      className="img-fluid "
                    />
                    1 minute
                  </p>
                </div>
              </div>
              <div>
                <div className="home-blog-card" >
                  <img
                    src={require("../assets/images/slider_img.png")}
                    alt="img"
                    className="img-fluid mx-auto slider_img mb-4"
                  />
                  <span>+40 USD in x4</span>
                  <h3 className="mt-4">
                    <a href="#">ID 124587</a>
                  </h3>
                  <p className="d-flex justify-content-center mt-4">
                    <img
                      src={require("../assets/images/download_arrow.png")}
                      alt="img"
                      className="img-fluid "
                    />
                    1 minute
                  </p>
                </div>
              </div>
              <div>
                <div className="home-blog-card">
                  <img
                    src={require("../assets/images/slider_img.png")}
                    alt="img"
                    className="img-fluid mx-auto slider_img mb-4"
                  />
                  <span>+40 USD in x4</span>
                  <h3 className="mt-4">
                    <a href="#">ID 124587</a>
                  </h3>
                  <p className="d-flex justify-content-center mt-4">
                    <img
                      src={require("../assets/images/download_arrow.png")}
                      alt="img"
                      className="img-fluid "
                    />
                    1 minute
                  </p>
                </div>
              </div>
              <div>
                <div className="home-blog-card">
                  <img
                    src={require("../assets/images/slider_img.png")}
                    alt="img"
                    className="img-fluid mx-auto slider_img mb-4"
                  />
                  <span>+40 USD in x4</span>
                  <h3 className="mt-4">
                    <a href="#">ID 124587</a>
                  </h3>
                  <p className="d-flex justify-content-center mt-4">
                    <img
                      src={require("../assets/images/download_arrow.png")}
                      alt="img"
                      className="img-fluid "
                    />
                    1 minute
                  </p>
                </div>
              </div>
              <div>
                <div className="home-blog-card">
                  <img
                    src={require("../assets/images/slider_img.png")}
                    alt="img"
                    className="img-fluid mx-auto slider_img mb-4"
                  />
                  <span>+40 USD in x4</span>
                  <h3 className="mt-4">
                    <a href="#">ID 124587</a>
                  </h3>
                  <p className="d-flex justify-content-center mt-4">
                    <img
                      src={require("../assets/images/download_arrow.png")}
                      alt="img"
                      className="img-fluid "
                    />
                    1 minute
                  </p>
                </div>
              </div>
              <div>
                <div className="home-blog-card">
                  <img
                    src={require("../assets/images/slider_img.png")}
                    alt="img"
                    className="img-fluid mx-auto slider_img mb-4"
                  />
                  <span>+40 USD in x4</span>
                  <h3 className="mt-4">
                    <a href="#">ID 124587</a>
                  </h3>
                  <p className="d-flex justify-content-center mt-4">
                    <img
                      src={require("../assets/images/download_arrow.png")}
                      alt="img"
                      className="img-fluid "
                    />
                    1 minute
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section> */}
      <section className="home_smart_tech">
        <Container>
          <div className="home_smart_tech_1">
            <Row>
              <Col xl={6}>
              <div
              className="home_smart_content mt-5"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-duration="1000"
            >
              <h5 className="h5_tag">
                <span>ABOUT DEFIQ TOKEN </span>
              </h5>
              <p>
                DefiQ Impact is a crypto project built on revolutionizing crypto
                multi utility decentralized wallet using blockchain technology.
              </p>
              <img
                src={require("../assets/images/banner_img_2.png")}
                className="img-fluid"
                width={462}
                height={382}
              />{" "}
            </div>
              </Col>
              <Col xl={6}>
              <div
              className="smart_section mt-5 mb-5 mt-lg-1 mt-md-1 "
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-duration="1000"
            >
              <Row>
                <Col xl={6} lg={6} md={6}>
                <div class="smart_card_content smart_content">
                <img
                src={require("../assets/images/smart_bg.png")}
                className="img-fluid"
                // width={462}
                // height={382}
              />
                <div className="bg_color">
              
                  <p>stable coin</p></div>
                <div className="smart_hover"></div>
              </div>
                </Col>
                <Col xl={6} lg={6} md={6}>
                <div class="smart_card_content smart_card_content_1 ">
                <img
                src={require("../assets/images/smart_bg_1.png")}
                className="img-fluid"
                // width={462}
                // height={382}
              />
                 <div className="bg_color">
                  <p>smart contract</p></div>
                <div className="smart_hover"></div>
              </div>


                </Col>
                <Col xl={6}lg={6} md={6}>
                <div class="smart_card_content smart_card_content_2">
                <img
                src={require("../assets/images/smart_bg_2.png")}
                className="img-fluid"
                // width={462}
                // height={382}
              />
                <div className="bg_color">
                <p>WEB 3.0</p></div>
                <div className="smart_hover"></div>
              </div>

                </Col>
                <Col xl={6} lg={6} md={6}>
                <div class="smart_card_content smart_card_content_3">
                <img
                src={require("../assets/images/smart_bg_3.png")}
                className="img-fluid"
                // width={462}
                // height={382}
              />
                 <div className="bg_color">
                 <p>Blockchain</p></div>
                <div className="smart_hover"></div>
              </div>
                </Col>
              </Row>
            

          
             

              

              <div className="smart_img">
                <img
                  src={require("../assets/images/logo_symbol_1.png")}
                  className="img-fluid "
                />
              </div>
            </div>
              </Col>
            </Row>
          

          
          </div>
        </Container>
      </section>
      <section className="home_accordion">
        <Container>
          <Row>
            <Col lg={12}>
              <h5
                className="h5_tag text-center mt-4"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-duration="500"
              >
                <span>Frequently Asked Questions</span>
              </h5>
            </Col>

            <Col lg={12}>
              <div class="accordion mt-5" id="accordionExample">
                <div
                  class="accordion-item"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-duration="1000"
                >
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <img
                        src={require("../assets/images/iq_symbol.png")}
                        className="img-fluid me-3"
                      />{" "}
                      <h5 class="h5tag">When will i get my royalty rewards?</h5>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p class="para">
                        Download Trust wallet and hold minimum of 0.001 BNB Coin
                        for gas fees along with 100 USDT BEP 20 to activate your
                        membership in DEFIQ PLUS Platform. Once you achieved
                        said criteria there after the system will automatically
                        credit your royalty rewards on every 15 days in your
                        trust wallet
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                >
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button"
                      type="button"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <img
                        src={require("../assets/images/iq_symbol.png")}
                        className="img-fluid me-3"
                      />
                      <h5 class="h5tag">How do I get income in DEFIQ PLUS?</h5>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p class="para">
                        After becoming member in DEFIQ PLUS you can copy your
                        referral link from dashboard and send it to your contact
                        to participate in DEFIQ PLUS , Once your contacts become
                        member by using your referral link you get 10% direct
                        referral income immediately and when they duplicate the
                        same you get matrix and upgrade level royalty income.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                >
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button"
                      type="button"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <img
                        src={require("../assets/images/iq_symbol.png")}
                        className="img-fluid me-3"
                      />
                      <h5 class="h5tag">Any deduction on withdrawal?</h5>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p class="para">
                        There is no deduction or hidden charges.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                >
                  <h2 class="accordion-header" id="headingfour">
                    <button
                      class="accordion-button"
                      type="button"
                      aria-expanded="false"
                      aria-controls="collapsefour"
                    >
                      <img
                        src={require("../assets/images/iq_symbol.png")}
                        className="img-fluid me-3"
                      />
                      <h5 class="h5tag">Can I register with higher package?</h5>
                    </button>
                  </h2>
                  <div
                    id="collapsefour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingfour"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p class="para">
                        No, First you have to register with 100 USDT, Then you
                        can upgrade with next package.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                >
                  <h2 class="accordion-header" id="headingfive">
                    <button
                      class="accordion-button"
                      type="button"
                      aria-expanded="false"
                      aria-controls="collapsefive"
                    >
                      <img
                        src={require("../assets/images/iq_symbol.png")}
                        className="img-fluid me-3"
                      />
                      <h5 class="h5tag">Any limitation on my income?</h5>
                    </button>
                  </h2>
                  <div
                    id="collapsefive"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingfive"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p class="para">No, there is no limit on your income.</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <footer>
        <Footer />
      </footer>
    </>
  );
}
