import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Web3 from "web3";
import moment from "moment";

//config
import config from "../../config/index.js";

//ABI
import contractAbi from "../../ABI/MLMContract.json";
import { Multicall } from "ethereum-multicall";
import { getFormatMulticall1, bignumber } from "../../helper/Action.js";

function ReferralHis() {
  const walletConnection = useSelector((state) => state?.walletConnection);
  const [UserReferralsHistory, setUserReferralsHistory] = useState([]);
  const [referrals, setReferrals] = useState([]);

  useEffect(() => {
    if (walletConnection.address) {
      let web3 = new Web3(walletConnection?.web3);
      let contractcall = new web3.eth.Contract(contractAbi, config.Contract);
      const web3call = new Web3(walletConnection?.provider || window.ethereum);
      const multicall = new Multicall({ web3Instance: web3call });
      getReferralHistory(web3, contractcall, multicall);
    }
  }, [walletConnection?.address]);

  const getReferralHistory = async (web3, contractcall, multicall) => {
    let referralData =  await getReferrals(contractcall);
    let userReferralDetails =  await getUserReferrals(multicall,referralData); 
    console.log(userReferralDetails,"userReferralDetailsuserReferralDetailsuserReferralDetails")
    setReferrals(referralData);
    setUserReferralsHistory(userReferralDetails);
  };

  const getUserReferrals = async(multicall,referralData) =>{
    const userReferralMultiCallArr = new Array();
    Array.from({ length: referralData?.length }, (value, index) => {
      let McallObj = {
        reference: `userReferral-${index}`,
        contractAddress: config.Contract,
        abi: contractAbi,
        calls: [
          {
            reference: "users",
            methodName: "users",
            methodParameters: [referralData?.[index]],
          },
        ],
      };
      userReferralMultiCallArr.push(McallObj);
    });

    let userReferralInfo = new Array();
    try {
      const multiCallResults = await multicall.call(userReferralMultiCallArr);
      const usrReferralInfo = await Promise.all(
        userReferralMultiCallArr.map((_, i) =>
          getFormatMulticall1(multiCallResults, `userReferral-${i}`, 0)
        )
      );
      userReferralInfo = await Promise.all(
        usrReferralInfo.map(async (element, index) => {
          console.log(element,"usrReferralDatausrReferralDatausrReferralData11111");
         const joinedDate = await bignumber(element[11]);
         const id = await bignumber(element[2]);
         const investAmount = await bignumber(element[9]);

        //  console.log(usrReferralData,"usrReferralDatausrReferralDatausrReferralData");

        //  bool isExist;
        //  bool isBlocked;
        //  uint256 id;
        //  address parent;
        //  address upliner;
        //  address right;
        //  address left;
        //  address[] referrals;
        //  uint256 totalInc;
        //  uint256 teamCount;
        //  uint256 investAmount;
        //  uint256 userCurrentPlan;
        //  uint256 joinedDate;
        //  uint256 stakingAmount;

          return {
            joinedDate: joinedDate,
            id: id,     
            investAmount: Number(investAmount) / 1e18,
            refIndex : referralData?.[index]
          };
        })
      );
    } catch (error) {
      console.error("Error creating genealogy tree:", error);
      return null;
    }
    return userReferralInfo;
  }

  const getReferrals = async(contractcall) =>{
    let referralData =  await contractcall.methods.getReferrals(walletConnection.address).call();
    return referralData;
  }
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };


  return (
    <>
      <div className="dashboard_table dashboard_table_1 referral_table">
        <div className={`dashboard_header ${isExpanded ? 'expanded' : 'not-expanded'}`}>
        <div className="mobile_img_none">
            <img
                src={require("../../assets/images/dashboard/user_icon_9.png")}
                className="img-fluid"
                />
                <h6 >Referral Details</h6>
                </div>
              <img
              src={require("../../assets/images/dashboard/arrow.png")}
              className="img-fluid me-5 rotate"
              data-bs-toggle="collapse"
              href="#collapseExamplefour"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
              onClick={toggleCollapse}
            />
                </div>
                <div className=" collapse collapse_content" id="collapseExamplefour">
                {UserReferralsHistory && UserReferralsHistory?.length!=0 ? (
                <div className="table-responsive">
                    <table class="table mt-4">
                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Date & Time</th>
                                <th scope="col">User ID</th>
                                <th scope="col">Address</th>
                                <th scope="col">upgraded</th>
                            </tr>
                        </thead>
                        <tbody>
                        {UserReferralsHistory && UserReferralsHistory.map((item, index) => (
                            <tr>
                                <th scope="row">{index}</th>
                                <td>{moment(Number(item?.joinedDate * 1000)).format("lll")}</td>
                                <td>{item?.id}</td>
                                <td>{item.refIndex}</td>
                                <td>{(item?.investAmount).toFixed(2)} USD</td>
                            </tr>
                        ))}
                            
                        </tbody>
                    </table>
                </div>
                ):(
                    <center><h6>No History</h6></center>
                )}
                </div>
            </div>
    </>
  );
}

export default ReferralHis;
