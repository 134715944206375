
import axios from 'axios'
import config from '../config/index'
export const getUserRoyaltyBalance = async (data) => {
    try {

        const respDate = await axios({
            url: `${config.Baseurl}/api/user/RoyaltyBalance`,
            method: 'post',
            data: data
        })

        console.log(respDate, 'respDate')
        if (respDate.data.status) {
            return respDate.data.result
        } else {
            console.log('error')
        }
    } catch (error) {
        console.log(error)
    }
}

export const getRoyaltyDataHistory = async (data) => {
    try {

        const respDate = await axios({
            url: `${config.Baseurl}/api/user/getRoyaltyDataHistory`,
            method: 'get',
            params: data
        })

        console.log(respDate, 'respDate')
        if (respDate.data.status) {
            return respDate.data.result
        } else {
            console.log('error')
        }
    } catch (error) {
        console.log(error)
    }
}


