import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/esm/Container.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import { CircularProgressbar } from "react-circular-progressbar";

import Web3 from "web3";
import { toastAlert } from "../../helper/toastAlert.js";

//config
import config from "../../config/index.js";

//ABI
import contractAbi from "../../ABI/MLMContract.json";
import TokenABI from "../../ABI/TokenContract.json";
import PropagateLoader from "react-spinners/PropagateLoader";

function PlanDisplay() {
  const override = {
    margin: "0 auto",
    borderColor: "red",
    position: "fixed",
    top: "50%",
    left: "50%",
    marginLeft: "-32px",
    marginTop: "-32px",
    zIndex: "99999",
  };

  const walletConnection = useSelector((state) => state?.walletConnection);
  const [loader, setLoader] = useState(false);
  const [poolinfo, setpoolinfo] = useState([]);
  const [referraldata, setreferraldata] = useState([]);

  useEffect(() => {
    getpooldetail();
  }, [walletConnection?.address]);

  //get pool info  fun.
  const getpooldetail = async () => {
    console.log("saranRErender1");
    if (walletConnection.address) {
      if (!walletConnection?.userdetails?.isExist) {
        window.location.href = "/";
      }

      var web3 = new Web3(walletConnection?.web3);
      var contractcall = new web3.eth.Contract(contractAbi, config.Contract);
      let poollength = await contractcall.methods.getPoolLenght().call();

      const tempPoolArr = [];
      setLoader(true);
      var Alreay = 0;
      for (let i = 0; i < poollength; i++) {
        console.log("saranRErender2");
        const pooldata = await contractcall.methods.poolInfo(i).call();
        const poolinfodata = await contractcall.methods
          .userPoolInfo(i, walletConnection.address)
          .call();
        var isExistD = 0;
        if (
          Number(poolinfodata.investAmount) / 1e18 >=
          Number(pooldata.poolAmount) / 1e18
        ) {
          isExistD = 1;
        } else if (
          (Number(poolinfodata.investAmount) === 0 ||
            Number(poolinfodata.investAmount) / 1e18 <
              Number(pooldata.poolAmount) / 1e18) &&
          Alreay == 0
        ) {
          isExistD = 2;
          Alreay = 1;
        } else {
          isExistD = 0;
        }

        var investAmount = Number(poolinfodata.investAmount);
        var poolAmount = Number(pooldata.poolAmount);
        investAmount = investAmount > 0 ? (investAmount / 1e18) : 0;
        poolAmount = poolAmount > 0 ? (poolAmount / 1e18) : 0;

        var percenataget =  investAmount > 0 ? investAmount / poolAmount  * 100 : 0;
        console.log(Number(poolinfodata.investAmount) / 1e18,"Number(poolinfodata.investAmount) / 1e18")
        tempPoolArr.push({
          poolNo: i,
          poolAmount: Number(pooldata.poolAmount) / 1e18,
          isexitsNew: isExistD,
          investAmount: percenataget.toFixed(0),
        });
      }
      
      console.log(tempPoolArr, "poolinfodatapoolinfodatapoolinfodata=====");
      setLoader(false);
      setpoolinfo(tempPoolArr);
    }
  };

  // Upgrade plan fun.
  const UpgradePlan = async (poolid) => {
    console.log("saranRErender2");
    try {
      if (walletConnection.address) {
        setLoader(true);

        var web3 = new Web3(walletConnection?.web3);
        const ContractToken = new web3.eth.Contract(
          TokenABI,
          config.TokenContract
        );
        var contractcall = new web3.eth.Contract(contractAbi, config.Contract);

        let firstPlan = await contractcall.methods.poolInfo(poolid).call();
        let planAmount = Number(firstPlan.poolAmount) / config.decimalVal;

        let tokenBal = await ContractToken.methods
          .balanceOf(walletConnection.address)
          .call();

        if (Number(planAmount) > Number(tokenBal / 1e18)) {
          toastAlert("error", `Your ${config.TokenName} balance is low`);
          setLoader(false);
          return false;
        }

        let estimateGas = await ContractToken.methods
          .approve(config.Contract, (planAmount * 1e18).toString())
          .estimateGas({ from: walletConnection.address })
          .catch((err) => console.log(err));

        let gasPriceUsdt = await web3.eth.getGasPrice();
        let estimateGasFee = (estimateGas * gasPriceUsdt) / 1e18;

        var balance = await web3.eth.getBalance(walletConnection.address);

        if (estimateGasFee > balance / 1e18) {
          toastAlert("error", "Your BNB balance is low");
          setLoader(false);

          return false;
        }

        await ContractToken.methods
          .approve(config.Contract, (planAmount * 1e18).toString())
          .send({ from: walletConnection.address })
          .then(async (res) => {
            await contractcall.methods
              .rejoin(poolid)
              .send({ from: walletConnection.address })
              .then(async (res) => {
                toastAlert("success", "Upgrade Successfully");
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
                setLoader(false);
              });
          });
      }
    } catch (err) {
      console.log(err);
      toastAlert("error", "Transaction Rejected");
      setLoader(false);
    }
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };
  const percentage = 75;

  return (
    <>
      <Container>
        <div className="dashboard_page">
          <div
            className={`dashboard_header ${
              isExpanded ? "expanded" : "not-expanded"
            }`}
          >
              <div className="mobile_img_none">
            <img
                src={require("../../assets/images/dashboard/user_icon_8.png")}
                className="img-fluid"
                />
            <h2 className="h2_tag">
              Package <span>Details</span>
            </h2>
            </div>
            <img
              src={require("../../assets/images/dashboard/arrow.png")}
              className="img-fluid me-5 rotate"
              data-bs-toggle="collapse"
              href="#collapseExampleone"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
              onClick={toggleCollapse}
            />
          </div>
          <div className="collapse collapse_content" id="collapseExampleone">
            <Row>
              {poolinfo &&
                poolinfo.map((item, index) => (
                  <Col lg={4} md={6}>
                    <div
                      className={`dashboard_upgrade ${
                        item.isexitsNew == 2 ? "rejoined" : "join_now"
                      } mt-2`}
                    >
                      <div>
                        <h6>Package {item?.poolNo + 1}</h6>
                        <div className="package_upgrade mt-3">
                          <h6>Upgraded Amount</h6>
                          <p>$ {item.poolAmount}</p>
                          {/* <p>Upgraded $ {(item.investAmount).toFixed(2)}</p> */}
                        </div>
                      </div>
                      <div className="package_display mt-3">
                      <div className="package_upgrade_chat">
                        <CircularProgressbar
                          value={item.investAmount}
                          text={`${item.investAmount}%`}
                          strokeWidth={12}
                          circleRatio={0.75}
                          
                        />
                      </div>
                        {item.isexitsNew == 1 ? (
                          <button className="join_btn">Completed</button>
                        ) : item.isexitsNew == 2 ? (
                          <button
                            className="join_btn"
                            onClick={() => UpgradePlan(index)}
                          >
                            Upgrade
                          </button>
                        ) : (
                          <button className="join_btn">Upgrade</button>
                        )}
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
        </div>
      </Container>
      {loader && (
        <div className="loadBefore">
          <PropagateLoader
            color={"#f6dd07"}
            loading={loader}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
}

export default PlanDisplay;
