
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
    useAccount,
    useConnect,
    useDisconnect,
    useNetwork,
    useSwitchNetwork,
    useWalletClient
} from "wagmi";
import Web3 from "web3";
import contractAbi from "../ABI/MLMContract.json";
import TokenABI from "../ABI/TokenContract.json";
import config from "../config/index.js";
import {
    walletClientToSigner
} from "../helper/ethersConnect.js";
import { toastAlert } from "../helper/toastAlert";
import { setWallet } from "../reducers/Actions";

function ConnectWallet() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [bnbBalance, setbnbBalance] = useState(0);
    const [tokenBalance, setTokenBalance] = useState();
    const [loader, setLoader] = useState(false);
    const [referral, setreferral] = useState(id == undefined ? "" : id)
    const walletConnection = useSelector((state) => state.walletConnection);
  
    const location = useLocation();
    const { pathname } = location;
  
    useEffect(() => {
        loadScript();
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", function (accounts) {
                // console.log(accounts, "accountsef");
                window.location.reload();
            });
        }
    }, []);

    function loadScript() {
        // Navbar Sticky
        var t = $(".navbar-sticky");
        $(window).on("scroll load", function (e) {
            var e = $(window).scrollTop();
            120 <= e
                ? t.addClass("navbar-sticky--moved-up")
                : t.removeClass("navbar-sticky--moved-up");
            250 <= e
                ? t.addClass("navbar-sticky--transitioned")
                : t.removeClass("navbar-sticky--transitioned");
            500 <= e
                ? t.addClass("navbar-sticky--on")
                : t.removeClass("navbar-sticky--on");
        });
    }


    const network = useSwitchNetwork({
        onMutate(args) {
            console.log("Mutate", args);
        },
        onError(error) {
            console.log("Error", error);
        }
    });


    useAccount({
        onConnect: ({ address, connector, isReconnected }) => {
        },
        onDisconnect: () => {
            // console.log("Disconnected");
            var web3 = new Web3(config.rpcUrl);
            dispatch(
                setWallet({
                    network: config.NetworkId,
                    web3: web3,
                    address: "",
                    provider: null,
                    connect: "",
                    isChange: ""
                })
            );
            // setTimeout(function () {
            //   window.location.reload(false);
            // }, 1500);
        }
    });

    const { disconnect } = useDisconnect();
    const { connect, connectors, error, isLoading, pendingConnector } =
        useConnect();

    const { chain, chains } = useNetwork();
    let chainId = config.NetworkId;

    const { data: walletClient } = useWalletClient({ chainId });


    useEffect(() => {
        setConnection();
    }, [walletClient, chain?.network]);

    async function setConnection() {
        if (walletClient && chain && chain.id == config.NetworkId) {
            var { signer, transport } = walletClientToSigner(walletClient);
            var web3 = new Web3(transport);
            var contractcall = new web3.eth.Contract(
                contractAbi,
                config.Contract
            );
            let userdetails = await contractcall.methods.users(walletClient.account.address).call();

            if (!userdetails?.isExist && id != undefined) {
                window.$("#Join_modal").modal("show");
            }

            if (chain.id == config.NetworkId) {
                dispatch(
                    setWallet({
                        network: config.NetworkId,
                        web3: web3,
                        address: walletClient.account.address,
                        provider: transport,
                        connect: "yes",
                        isChange: "false",
                        userdetails: userdetails
                    })
                );
                localStorage.setItem("iswalletconnect", "yes");
            }
            // console.log(signer, transport, "@@@@@@@@@@@@@@@@@@@1");
        } else {
            var web3 = new Web3("https://bsc-dataseed1.binance.org");
            dispatch(
                setWallet({
                    network: config.NetworkId,
                    web3: web3,
                    address: "",
                    provider: null,
                    connect: "",
                    isChange: ""
                })
            );
        }
    }

    useEffect(() => {
        getconnect();
    }, [walletConnection, walletClient]);

    async function getconnect() {
        try {
            if (
                walletConnection &&
                walletConnection.web3 &&
                walletConnection.address &&
                walletConnection.address != undefined &&
                walletConnection.address != "" &&
                walletConnection.network != ""
            ) {
                let address = walletConnection.address;
                let provider = walletConnection.provider;

                if (provider) {
                    var web3 = new Web3(provider);
                    try {
                        if (typeof provider !== "undefined") {
                            //getBNB balance
                            let balgetAddr = walletConnection.address;
                            var balance = await web3.eth.getBalance(balgetAddr);
                            // console.log(balance, "balance");

                            var currbal = balance / 1e18;
                            setbnbBalance(currbal.toFixed(2));

                            const ContractToken = new web3.eth.Contract(
                                TokenABI,
                                config.TokenContract
                            );

                            let tokenBal = await ContractToken.methods
                                .balanceOf(balgetAddr)
                                .call();

                            let wMaticBal = tokenBal / 1e18;
                            // console.log(wMaticBal, "wMaticBal");
                            setTokenBalance(wMaticBal);
                        }
                    } catch (err) {
                        console.log(err, "Error getconnect");
                    }
                }
            }
        } catch (err) {
            console.log(err, "ErrorOnNavbar getconnect");
        }
    }

    async function connectfunction(connector) {
        try {
            let check = isMobile();
            // console.log(connector, "connectorconnector");
            if (
                check &&
                !window.ethereum &&
                connector &&
                connector.connector.id != "walletConnect"
            ) {
                await connectMetamaskMobile();
                return;
            }
            if (window.ethereum) {
                var web3 = new Web3(window.ethereum);
                var currnetwork = await web3.eth.net.getId();
                if (
                    parseInt(currnetwork) != config.NetworkId &&
                    connector.connector.id == "metaMask"
                ) {
                    toastAlert("error", "Please connect smart chain");
                    return;
                }
            }

            var resp = connect(connector);
            window.$("#connect_walletModalLabel").modal("hide");
            setTimeout(function () {
                // console.log("Reload");
                // window.location.reload(false);
            }, 1000);
        } catch (err) { }
    }

    function isMobile() {
        let check = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4)
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }

    const connectMetamaskMobile = () => {
        const dappUrl = window.location.href.split("//")[1].split("/")[0];
        const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
        window.open(metamaskAppDeepLink, "_self");
    };


    return (
        <>
            <button
                className="primary_btn"
                data-bs-toggle="modal"
                data-bs-target="#connect_walletModalLabel"
            >

                <span class="icon">
                    <img
                        src={require("../assets/images/nav_icon.png")}
                        width={20}
                        height={20}
                        className="img-fluid me-2"
                     
                    />{" "}
                </span>
                <span class="text2">Connect Wallet</span>
            </button>

          

            <div
        className="modal fade primary_modal"
        id="connect_walletModalLabel"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">

                  {connectors &&
                    connectors.length > 0 &&
                    connectors.map((connector) => (
                      <>
                        {connector && connector.id == "metaMask" ? (
                          <a target="_blank" className="mb-3 d-block">
                            <button
                              type="button"
                              className="connect_wallet_button"
                              onClick={() => connectfunction({ connector })}
                            >
                              <img
                                src={require("../assets/images/dashboard/metamask.png")}
                                height="28"
                                width="28"
                                className="img-fluid"
                              />{" "}
                              <span>MetaMask</span>
                            </button>
                          </a>
                        ) : (
                          ""
                        )}

                        {connector && connector.id == "walletConnect" ? (

                          <a target="_blank" className="mb-3 d-block">
                            <button
                              type="button"
                              className="connect_wallet_button"
                              onClick={() => connectfunction({ connector })}
                            >
                              <img
                                src={require("../assets/images/dashboard/trustwallet.png")}
                                height="28"
                                width="28"
                                className="img-fluid"
                              />{" "}
                              <span> Wallet Connect</span>
                            </button>
                          </a>
                        ) : (
                          ""
                        )}
                      </>
                    ))}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        </>
    )
}

export default ConnectWallet